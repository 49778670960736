/* resets */
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

body, html {
  height: 100% !important;
  width: 100% !important;
  margin: 0;
  display: table;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background: #f1f3f7;
  display: table-cell;
  vertical-align: middle;
}

/* login page */
.login-page {
  display: table;
  width: 360px;
  margin: auto;
}

.login-wrapper {
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  padding: 15px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
  z-index: 0;
}
.login-wrapper .bg-blue {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 80px;
  background: #0068e1;
  overflow: hidden;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  z-index: -1;
}
.login-wrapper .bg-blue .reflection {
  position: absolute;
  left: -105px;
  top: 0;
  height: 300px;
  width: 300px;
  background: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transform: rotate(50deg);
}
.login-wrapper .form-inner {
  background: #ffffff;
  border-radius: 3px;
  border: 1px solid #e9e9e9;
  margin: 15px 0 0;
  padding: 0 15px 10px;
}
.login-wrapper .reset-password p {
  margin-bottom: 20px;
}
.login-wrapper .reset-password .login-form > button {
  margin-bottom: 10px;
}
.login-wrapper .reset-password .input-icon {
  top: 5px !important;
}
.login-wrapper .reset-password > a {
  margin: 5px auto 5px;
}
.login-wrapper h3 {
  margin: 15px 0;
}
.login-wrapper .form-group {
  position: relative;
}
.login-wrapper .form-group label > span {
  color: #fc4b4b;
  margin-left: 4px;
}
.login-wrapper .form-group .form-control {
  padding-left: 36px;
}
.login-wrapper button {
  display: table;
  margin: 20px auto 5px;
  padding-left: 60px;
  padding-right: 60px;
}
.login-wrapper a {
  display: table;
  margin-top: 10px;
  color: #0068e1;
}
.login-wrapper a:hover {
  text-decoration: underline;
}

.reset-password button {
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (max-width: 415px) {
  .login-page {
    width: 100%;
    margin: 0;
    padding: 0 15px;
  }
  .login-page .checkbox {
    float: none !important;
    display: table;
    margin: 10px auto 0;
  }
  .login-page a {
    float: none !important;
    display: table;
    margin: 10px auto;
  }
}